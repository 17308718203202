/* --------------------------------
[JSファイルの説明]
・gulpで scripts.json の libs の各ファイルを連結し、 /js/libs.js に書き出している
・gulpで scripts.json の scripts の各ファイルを連結＆圧縮し、 /js/scripts.js に書き出している
・HTMLでは上記の libs.js と scripts.js を読み込んでいる
・もしもgulpの環境が無くて更新が困難な場合は、書き出したJSは無視して scripts.json の各ファイルをHTMLで直接読み込んでも動作は可能
-------------------------------- */

//aタグ スムーススクロール
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;

    position -= $('header').innerHeight() - 1;

    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

//fade
$(function(){
  var fade = $('.fade, .fadeAni');

  fade.each(function(index, el) {
    var t = $(this);

    setTimeout(function(){
      $(window).on('resize scroll', function(event) {
        run();
      });

      run();
    }, 200);

    function run() {
      var winT = $(window).scrollTop();
      var winH = $(window).innerHeight();
      var winB = winT + winH;
      var tT = t.offset().top;
      var tH = t.innerHeight();
      var tB = tT + tH;

      var pointH = tT + 50;
      var pointSc = winB;
      var pointD = 200;

      var h = t.attr('data-fade-h');
      if(h) {
        pointH = tT + tH * h / 100;
      }

      var sc = t.attr('data-fade-sc');
      if(sc) {
        pointSc = winT + winH * sc / 100;
      }

      var d = t.attr('data-fade-delay');
      if(d) {
        pointD = d;
      }

      if(pointH < pointSc) {
        setTimeout(function(){
          t.addClass('is-fade');
          next();
        }, pointD);
      }
    }

    function next() {
      var nextStr = t.attr('data-fade-next-target');
      if(nextStr) {
        var nextArr = nextStr.split(' ');
        if(nextArr) {
          for (var i = 0; i < nextArr.length; i++) {
            var next = nextArr[i];
            var nextTar = $('[data-fade-next-id="' + next + '"]');
            if(nextTar[0]) {
              var nextDelay = t.attr('data-fade-next-delay');
              var delay = 0;
              if(nextDelay) {
                delay = parseInt(nextDelay);
              }

              var nextStep = t.attr('data-fade-next-step');
              var step = 0;
              if(nextDelay) {
                step = delay;
              }
              if(nextStep) {
                step = parseInt(nextStep);
              }

              nextTar.each(function(index, el) {
                var t2 = $(this);
                var step2 = step * index + delay;

                setTimeout(function(){
                  t2.addClass('is-fade-next');
                  t2.attr('data-test', step2);
                }, step2);
              });
            }
          }
        }
      }
    }
  });
});
